import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Grid, MenuItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Select from "@material-ui/core/Select";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { canChangeCountrySetting } from "../../CountrySettings/CountryAccessControlUtils";
import { logOutUser } from "../../Login/redux/userSlice";
import { topMenuStyles } from "./TopMenuStyles";
import { fetchPendingApprovalRequest } from "../../ApprovalRequest/Redux/ApprovalRequestSlice";
import { fetchCountrySettings } from "../../CountrySettings/redux/countrySettingsSlice";
import { APP_ROUTES, CURRENT_COUNTRY } from "../../../config/constants";
import { changeCountry } from "../../../config/redux/appConfigSlice";
import { fetchAllAreasOfCountry, fetchAllRegionsOfCountry, fetchServiceInActiveStatus, selectAllCountries } from "../../../config/redux/configurationsSlice";

import AccountCircle from "../../assets/svg/AccountCircle.svg";
import CarrefourLogo from "../../assets/svg/CarrefourLogo.svg";
import DOSLogo from "../../assets/svg/DOSTitle.svg";
import { ReactComponent as CountryDropDownIcon } from "../../assets/svg/DownArrow.svg";
import LineSVG from "../../assets/svg/Line.svg";
import settingsIcon from "../../assets/svg/Settings.svg";
import { resetPolygonServiceConfigurationServiceState } from "../../PlolygonServices/Redux/PlolygonServicesSlice";

function TopMenu({ handleListItemClick, selectAllActiveInActiveServiceTypeRecord }) {
  const topMenuClasses = topMenuStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state);
  const countries = useSelector(selectAllCountries);

  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [showSetting, setShowSetting] = useState(false);

  const onLogout = () => {
    dispatch(logOutUser());
  };

  const handleCountryChange = (event) => {
    const { value } = event.target;
    setCountry(value);
    let selectedCountryObj = countries.filter((c) => c.countryId === value)[0];
    if (selectedCountryObj !== undefined && selectedCountryObj.countryId !== undefined && selectedCountryObj.countryId !== "") {
      localStorage.setItem(CURRENT_COUNTRY, JSON.stringify(selectedCountryObj));
      dispatch(changeCountry(selectedCountryObj));
      dispatch(fetchCountrySettings(selectedCountryObj));
      dispatch(fetchAllRegionsOfCountry(selectedCountryObj.countryId));
      dispatch(fetchAllAreasOfCountry(selectedCountryObj.countryId));
      handleListItemClick(event, 0, APP_ROUTES.DASHBOARD);
      dispatch(fetchPendingApprovalRequest({ countryId: selectedCountryObj.countryId, status: ["PENDING"] }));
      dispatch(fetchServiceInActiveStatus(selectedCountryObj.countryId));
      dispatch(resetPolygonServiceConfigurationServiceState());
      history.push(APP_ROUTES.DASHBOARD);
    }
  };

  useEffect(() => {
    if (user && user.userDetails && user.userDetails.userProfile && user.userDetails.userProfile.featureId && user.userDetails.userProfile.featureId.length > 0) {
      let isShowSetting = canChangeCountrySetting(user.userDetails);
      setShowSetting(isShowSetting);
    }
  }, [user]);

  useEffect(() => {
    if (countries && countries.length > 0 && user && user.userDetails && user.userDetails.userProfile) {
      let storedCountry = localStorage.getItem(CURRENT_COUNTRY) ? JSON.parse(localStorage.getItem(CURRENT_COUNTRY)) : undefined;
      let selectedCountries = countries.filter((item) => user.userDetails.userProfile.countryId.includes(item.countryId));
      if (storedCountry !== undefined && storedCountry !== "" && storedCountry.countryId !== undefined && user.userDetails.userProfile.countryId.includes(storedCountry.countryId)) {
        setCountry(storedCountry.countryId);
      } else {
        setCountry(selectedCountries[0].countryId);
      }
      setCountryList(selectedCountries);
    }
  }, [countries, user]);

  return (
    <AppBar position="fixed" className={topMenuClasses.appBar}>
      <Toolbar className={topMenuClasses.toolBar}>
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item>
            <Grid container spacing={0} justifyContent="flex-start">
              <Typography variant="h6" noWrap>
                <Link to={`${APP_ROUTES.DASHBOARD}`} onClick={(e) => handleListItemClick(e, 0, APP_ROUTES.DASHBOARD)}>
                  <img src={DOSLogo} alt="DOSText" className={topMenuClasses.logoName} />
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={0} justifyContent="flex-end">
              {selectAllActiveInActiveServiceTypeRecord !== undefined && Array.isArray(selectAllActiveInActiveServiceTypeRecord) && selectAllActiveInActiveServiceTypeRecord.length > 0 && (
                <>
                  <Typography className={topMenuClasses.inactiveServiceTypeSpanStyle}>{`${selectAllActiveInActiveServiceTypeRecord.length === 1 ? "Service type" : "Service types"}`}</Typography>
                  <Typography className={topMenuClasses.inactiveServiceTypeBoldSpanStyle}>{`${selectAllActiveInActiveServiceTypeRecord
                    .map((serviceType) => serviceType)
                    .join(", ")
                    .replace(/_/g, " ")}`}</Typography>
                  <Typography className={topMenuClasses.inactiveServiceTypeSpanStyle}>{`${selectAllActiveInActiveServiceTypeRecord.length === 1 ? "is" : "are"} In-active`}</Typography>
                  <img src={LineSVG} className={topMenuClasses.linesvg} alt="line" />
                </>
              )}
              <div className={topMenuClasses.countryDivStyle}>
                <InputLabel className={topMenuClasses.CountryLabel}>Country: </InputLabel>
                {countries !== undefined && countries.length && country && (
                  <FormControl>
                    <Select
                      disableUnderline={true}
                      id="country"
                      renderValue={() => {
                        return countryList.length ? countryList.find((c) => c.countryId === parseInt(country))?.name : "";
                      }}
                      value={countries.length ? country : ""}
                      onChange={handleCountryChange}
                      label="Country"
                      name="country"
                      className={topMenuClasses.inputRounded}
                      IconComponent={CountryDropDownIcon}
                      MenuProps={{
                        anchorOrigin: { vertical: "bottom", horizontal: "left" },
                        getContentAnchorEl: null,
                        classes: { paper: topMenuClasses.dropdownMenuStyle },
                      }}
                    >
                      {countryList.map((item) => (
                        <MenuItem key={item.countryId} value={item.countryId} className={topMenuClasses.countryMenuItemContainer}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={9} className={topMenuClasses.fontSize500}>
                              {item.name}
                            </Grid>
                            {showSetting && (
                              <Grid item xs={3}>
                                <Link to={`${APP_ROUTES.COUNTRY_SETTINGS}/${item.name}`} className={topMenuClasses.countryMenuItem} onClick={(e) => e.stopPropagation()}>
                                  <img src={settingsIcon} alt="settings" />
                                </Link>
                              </Grid>
                            )}
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <img src={LineSVG} className={topMenuClasses.linesvg} alt="line" />
              <div className={topMenuClasses.userDetail}>
                {`${user?.userDetails?.firstName || "-"} ${user?.userDetails?.lastName || "-"}`}
                <br />
                <div className={topMenuClasses.logoutButton} onClick={onLogout}>
                  {`Logout`}
                </div>
              </div>
              <ListItemIcon className={topMenuClasses.accountCircleDiv}>
                <img src={AccountCircle} className={topMenuClasses.accountCircle} alt="accountImg" />
              </ListItemIcon>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(TopMenu);
