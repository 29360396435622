import { MAF_USER, SUPER_ADMIN, READONLY_USER } from "../../../config/constants";
import { getDateFromData } from "../../../utils/DateUtils";

import { ACTIVE_STATUS, AddUpdateUserError, AddUpdateUserErrorState, AddUpdateUserInitialState, AddUpdateUserState, IN_ACTIVE_STATUS } from "../AddUpdateUserInterface";

export const DIALOG_TITLE_NEW_USER = "CREATE NEW USER";
export const DIALOG_TITLE_UPDATE_USER = "UPDATE USER";

export const RULE_ENGINE = "Rule Engine";
export const COUNTRY_SETTING = "Country Setting";

export const validateForm = (userObj: any) => {
  const DEFAULT_TEXT_ENTER: string = "Please enter";
  const DEFAULT_TEXT_SELECT: string = "Please select";
  let newErrors: AddUpdateUserError = AddUpdateUserErrorState;

  if (!userObj.firstName) {
    newErrors = { ...newErrors, firstName: `${DEFAULT_TEXT_ENTER} First Name` };
  }

  if (!userObj.lastName) {
    newErrors = { ...newErrors, lastName: `${DEFAULT_TEXT_ENTER} Last Name` };
  }

  if (!userObj.emailId) {
    newErrors = { ...newErrors, emailId: `${DEFAULT_TEXT_ENTER} Email Id` };
  }

  if (!userObj.role) {
    newErrors = { ...newErrors, role: `${DEFAULT_TEXT_SELECT} Role` };
  }

  if (userObj.serviceTypeId.length === 0) {
    newErrors = { ...newErrors, serviceTypeId: `${DEFAULT_TEXT_SELECT} Service Type` };
  }

  if (!userObj.country) {
    if (userObj.countryId.length === 0) {
      newErrors = { ...newErrors, countryId: `${DEFAULT_TEXT_SELECT} Country`, country: `${DEFAULT_TEXT_SELECT} Country` };
    }
  }

  if (userObj.status.length === 0) {
    newErrors = { ...newErrors, status: `${DEFAULT_TEXT_SELECT} Status` };
  }

  return {
    isError: Object.keys(newErrors).filter((err) => (newErrors as any)[err]).length > 0,
    newErrors: newErrors,
  };
};

export const createUserObject = (userData: any, userObj: any) => {
  const userDetails: AddUpdateUserState = AddUpdateUserInitialState;
  const {
    id,
    firstName,
    lastName,
    emailId,
    active,
    userProfile: { countryId, featureId, role, serviceTypeId },
  } = userData;

  userDetails.id = id;
  userDetails.firstName = firstName;
  userDetails.lastName = lastName;
  userDetails.emailId = emailId;
  userDetails.role = role;
  userDetails.serviceTypeId = serviceTypeId;
  if (role.includes(MAF_USER) || userObj.role.includes(SUPER_ADMIN) || role.includes(READONLY_USER)) {
    userDetails.countryId = countryId;
  } else {
    userDetails.country = countryId[0];
  }
  userDetails.status = active ? ACTIVE_STATUS : IN_ACTIVE_STATUS;
  userDetails.featureId = featureId;
  userDetails.userDataRecord = userObj.userDataRecord;

  return userDetails;
};

export const createUserRequestObject = (userObj: AddUpdateUserState) => {
  const { firstName, lastName, emailId, role, serviceTypeId, status, featureId, countryId, country, id } = userObj;

  const userRequest: any = {
    firstName,
    lastName,
    emailId,
    active: status === ACTIVE_STATUS ? true : false,
    userProfile: {
      role,
      serviceTypeId,
      featureId,
      countryId: country !== "" ? [country] : countryId,
    },
  };

  if (id) {
    userRequest.id = id;
  }
  return userRequest;
};

const getUserFeatures = (serviceList: Array<any>, item: any) => {
  let selectedFeatures = serviceList.filter((feature: any) => feature.id === item);
  if (Array.isArray(selectedFeatures) && selectedFeatures.length > 0) {
    return selectedFeatures[0].name;
  }
  return [];
};

export const updateUserRecordData = (userDataRecord: any, countriesList: any, serviceTypeList: any, additionalServicesList: any) => {
  if (userDataRecord && userDataRecord.content && Array.isArray(userDataRecord.content) && userDataRecord.content.length > 0) {
    let countryObj: any = {};
    if (Array.isArray(countriesList) && countriesList.length > 0) {
      countryObj = countriesList.reduce((prev: any, next: any) => ({ ...prev, ...{ [next["id"]]: next["name"] } }), {});
    }

    let serviceTypeObj: any = {};
    if (Array.isArray(serviceTypeList) && serviceTypeList.length > 0) {
      serviceTypeObj = serviceTypeList.reduce((prev: any, next: any) => ({ ...prev, ...{ [next["id"]]: next["displayName"] } }), {});
    }

    let userData = userDataRecord.content.map((item: any) => ({
      ...item,
      countryDisplayName: item.userProfile.countryId.map((item: any) => countryObj[item]).join(", "),
      serviceTypeName: item.userProfile.serviceTypeId.map((item: any) => serviceTypeObj[item]).join(", "),
      accessToDeliveryEngine: item.userProfile.featureId
        .map((item: any) => getUserFeatures(additionalServicesList, item))
        .join(", ")
        .toLowerCase()
        .includes(RULE_ENGINE.toLowerCase()),
      accessToCountrySetting: item.userProfile.featureId
        .map((item: any) => getUserFeatures(additionalServicesList, item))
        .join(", ")
        .toLowerCase()
        .includes(COUNTRY_SETTING.toLowerCase()),
    }));
    return userData;
  }
  return [];
};

export const DIALOG_USER_ID = "User ID";
export const DIALOG_USER_DETAIL_HEADER = "User Details";

export const createUserDetailsObject = (payloadData: any, userObj: any) => {
  const userDetails: AddUpdateUserState = createUserObject(payloadData, userObj);
  const { active, createdAt, createdBy, lastModifiedAt, lastModifiedBy } = payloadData;

  userDetails.active = active ? ACTIVE_STATUS : IN_ACTIVE_STATUS;

  userDetails.createdAt = getDateFromData(createdAt);
  userDetails.createdBy = createdBy;
  userDetails.lastModifiedAt = getDateFromData(lastModifiedAt);
  userDetails.lastModifiedBy = lastModifiedBy;

  return userDetails;
};

export const clearUserObj = (userObj: any) => {
  const userDetails: AddUpdateUserState = Object.assign({}, AddUpdateUserInitialState);

  userDetails.userDataRecord = [...userObj.userDataRecord];
  userDetails.firstName = "";
  userDetails.lastName = "";
  userDetails.emailId = "";
  userDetails.role = "";
  userDetails.serviceTypeId = [];
  userDetails.country = "";
  userDetails.countryId = [];
  userDetails.status = ACTIVE_STATUS;
  userDetails.featureId = [];
  userDetails.id = "";
  userDetails.active = "";
  userDetails.createdAt = "";
  userDetails.createdBy = "";
  userDetails.lastModifiedAt = "";
  userDetails.lastModifiedBy = "";

  return userDetails;
};

export const NO_MESSAGE_AVAILABLE = "No message available";
export const ERROR_UPDATE = "Error while Updating User.";
export const ERROR_CREATE = "Error while Creating User.";

export const getPageNumber = (userDataRecord: any) => {
  if (userDataRecord && userDataRecord.number && userDataRecord.number >= 0) {
    return userDataRecord.number;
  }
  return 0;
};

export const getMaxRecordPresent = (userDataRecord: any) => {
  if (userDataRecord && userDataRecord.totalElements) {
    return userDataRecord.totalElements;
  }
  return 0;
};
