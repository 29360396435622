import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { DELIVERY_FEE_TEXT_CONSTANT, SLOT_PROXIMITY_FEE_TITLE } from "../../../Utils/DeliveryFeeConstants";
import { ChangeConfigOtion } from "../../utils/DeliveryFeeConfigViewUtils";
import { isArrayValid, isEmptyOrNull } from "../../../../../utils/DataUtils";

import SelectBox from "../../../../common/SelectBox/SelectBox";
import SwitchButton from "../../../../common/SwitchButton/SwitchButton";
import TextBoxWithLabel from "../../../../common/TextBoxWithLabel/TextBoxWithLabel";
import { SlotProximityFeeConfigDTOListConfigurationInterface } from "../../../Utils/DeliveryFeeInterface";
import { generateDropdownWithMentionedIntervalItems } from "utils/helperFunctions";

interface SlotProximityFeeConfigurationInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  stateObj: any;
  isEditMode: any;
  stateLevelIndex: any;
  updateConfigPosId: any;
  updateConfigZoneName: any;
  enableThisFeature: any;
  use_existing_config_change_handler: any;
  config_change_existing_handler: any;
  //
  _handle_slot_proxomity_fee_calculation_toggle_change: any;
  handleSlotProximityFeeChange: any;
}

const SlotProximityFeeConfiguration = (props: SlotProximityFeeConfigurationInterface) => {
  const {
    classes,
    name,
    multiShipmentLevel,
    stateObj,
    isEditMode,
    stateLevelIndex,
    updateConfigPosId,
    updateConfigZoneName,
    enableThisFeature,
    use_existing_config_change_handler,
    config_change_existing_handler,
    //
    _handle_slot_proxomity_fee_calculation_toggle_change,
    handleSlotProximityFeeChange,
  } = props;

  const isSlotProximityFeeConfigurationRecordPresent = () => {
    return isArrayValid(stateObj.slotProximityFeeConfigDTOList);
  };

  return (
    <Grid container className={classes.dlvFeeServiceTypeDiv} key={`${name}-${stateLevelIndex}-service-Fee`}>
      <Grid container>
        <Grid item xs={4} className={classes.dlvFeeServiceTypeTextDivStyle}>
          <Typography className={classes.dlvFeeServiceTypeTextStyle}>{SLOT_PROXIMITY_FEE_TITLE}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.marginAuto}>
          {!stateObj.sameAsEarlier && (
            <Grid container justifyContent="flex-end">
              <Grid item xs={10} className={classes.useCalculationDivStyle}>
                <Typography className={classes.dlvFeeCalculationTextStyle}>Use this value in calculation</Typography>
              </Grid>
              <Grid item xs={2}>
                <SwitchButton
                  className={isEditMode && !stateObj.enableConfigEdit ? classes.disableSwitch : classes.switchStyle}
                  size="small"
                  checked={stateObj.active}
                  disabled={false}
                  handleChange={(e: any) => _handle_slot_proxomity_fee_calculation_toggle_change(e, multiShipmentLevel, name)}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4} className={enableThisFeature ? classes.marginAuto : classes.marginAutoDisabled}>
          <Grid container justifyContent="flex-end">
            <ChangeConfigOtion
              is_edit_mode={updateConfigPosId === "" && updateConfigZoneName === "" ? false : true}
              classes={classes}
              use_existing_checked={stateObj.sameAsEarlier}
              use_existing_config_change_handler={(event: any) => use_existing_config_change_handler(event, multiShipmentLevel, name, "slotProximityFee", "sameAsEarlier", enableThisFeature)}
              change_existing_checked={stateObj.enableConfigEdit}
              use_change_existing_config_change_handler={(e: any) => config_change_existing_handler(e, multiShipmentLevel, name, "slotProximityFee", "enableConfigEdit", enableThisFeature)}
            />
          </Grid>
        </Grid>
      </Grid>

      {stateObj?.active !== undefined && stateObj.active && isSlotProximityFeeConfigurationRecordPresent() && (
        <>
          {stateObj.slotProximityFeeConfigDTOList.map((slotObj: SlotProximityFeeConfigDTOListConfigurationInterface, slotObjIndex: number) => (
            <Grid container key={`slotObj-slotProximityFeeConfigDTOList-${slotObjIndex}`} className={classes.capacityUtilisationValueDiv} spacing={1}>
              <Grid item xs={3}>
                <SelectBox
                  label={"Proximity to Slot"}
                  name="maxTimeToSlot"
                  value={slotObj.maxTimeToSlot}
                  menuItems={generateDropdownWithMentionedIntervalItems(
                    DELIVERY_FEE_TEXT_CONSTANT.PROXIMITY_TO_SLOT_START,
                    DELIVERY_FEE_TEXT_CONSTANT.PROXIMITY_TO_SLOT_END,
                    DELIVERY_FEE_TEXT_CONSTANT.PROXIMITY_TO_SLOT_INERVAL_VALUE,
                    DELIVERY_FEE_TEXT_CONSTANT.PROXIMITY_TO_SLOT_ITEM_LABEL
                  )}
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleSlotProximityFeeChange(e, name, DELIVERY_FEE_TEXT_CONSTANT.SLOT_PROXIMITY_Time, slotObjIndex)}
                  className={classes.rightPaddingStyle}
                  disabled={!stateObj.enableConfigEdit}
                  error={isEmptyOrNull(slotObj.maxTimeToSlotErrorMessage) ? false : true}
                />
              </Grid>
              <Grid item xs={3}>
                <TextBoxWithLabel
                  label={`${DELIVERY_FEE_TEXT_CONSTANT.SLOT_PROXIMITY_FEE_TITLE}`}
                  name="deliveryFee"
                  value={slotObj.deliveryFee}
                  placeholderText={`${DELIVERY_FEE_TEXT_CONSTANT.SLOT_PROXIMITY_FEE_TITLE}`}
                  handleChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleSlotProximityFeeChange(e, name, DELIVERY_FEE_TEXT_CONSTANT.SLOT_PROXIMITY_FEE, slotObjIndex)}
                  type="number"
                  className={classes.rightPaddingStyle}
                  disabled={!stateObj.enableConfigEdit}
                  error={isEmptyOrNull(slotObj.deliveryFeeErrorMessage)}
                  helperText={slotObj.deliveryFeeErrorMessage}
                />
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default React.memo(SlotProximityFeeConfiguration);
