import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";

import { DELIVERY_FEE_TEXT_CONSTANT } from "../../Utils/DeliveryFeeConstants";
import { slotProximityFeeConfigDTOListInterface } from "../../Utils/DeliveryFeeInterface";
import { isUndefined } from "../../../../utils/DataUtils";

interface SlotProximityFeeConfigDTOListInterface {
  slotProximityFeeConfigDTOList: any;
  classes: any;
  stateObj: any;
}

const SlotProximityFeeConfigDTOList = (props: SlotProximityFeeConfigDTOListInterface) => {
  const { slotProximityFeeConfigDTOList, classes, stateObj } = props;

  return (
    <Card className={classes.additionDlvFeeMainCard}>
      <Grid container className={classes.additionDlvFeeInnerRowHeaderStyle}>
        <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.PROXIMITY_TO_SLOT_MIN_TIME}`}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.dlvFeeValueDisplayDivStyle}>
          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.PROXIMITY_TO_SLOT_MAX_TIME}`}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.dlvFeeValueDisplayDivStyle}>
          <Typography className={classes.dlvFeeValueDisplayTitleStyle}>{`${DELIVERY_FEE_TEXT_CONSTANT.SLOT_PROXIMITY_FEE_TITLE}`}</Typography>
        </Grid>
      </Grid>
      {slotProximityFeeConfigDTOList && Array.isArray(slotProximityFeeConfigDTOList) && slotProximityFeeConfigDTOList.length > 0 ? (
        slotProximityFeeConfigDTOList.map((config: slotProximityFeeConfigDTOListInterface, configIndex: any) => (
          <Grid container key={`${configIndex}-feeConfigList-config`} className={classes.additionDlvFeeInnerRowHeaderStyle}>
            <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayDataStyle}>{!isUndefined(config.minTimeToSlot) ? `${config.minTimeToSlot} mins` : ""}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayDataStyle}>{!isUndefined(config.maxTimeToSlot) ? `${config.maxTimeToSlot} mins` : ""}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.additionDlvFeeInnerRowDivStyle}>
              <Typography className={classes.dlvFeeValueDisplayDataStyle}>{!isUndefined(config.deliveryFee) ? config.deliveryFee : ""}</Typography>
            </Grid>
          </Grid>
        ))
      ) : (
        <></>
      )}
    </Card>
  );
};

export default React.memo(SlotProximityFeeConfigDTOList);
