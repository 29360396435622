import { useCallback } from "react";

import { Card, CardContent, Checkbox, FormControlLabel, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import AddPlusIcon from "../../../assets/svg/AddPlusIcon.svg";
import SubtractMinusIcon from "../../../assets/svg/SubtractMinusIcon.svg";
import { CREATE_TIME_BASED_CONFIGURATIONS, DELIVERY_FEE_TEXT_CONSTANT } from "../../Utils/DeliveryFeeConstants";
import { SlotCapacityViewUtilisationConfigurationInterface, slotProximityFeeConfigDTOListInterface } from "../../Utils/DeliveryFeeInterface";
import { isUndefined } from "../../../../utils/DataUtils";

interface AddConfigurationsCommonTextUIViewInterface {
  name: any;
  classes: any;
}

export const AddConfigurationsCommonTextUIView = (props: AddConfigurationsCommonTextUIViewInterface) => {
  const { name, classes } = props;
  return (
    <>
      <AddCircleOutlineIcon className={classes.addConfigIconStyle} />
      <Typography className={classes.addConfigTextStyle}>{`${CREATE_TIME_BASED_CONFIGURATIONS}`}</Typography>
    </>
  );
};

interface AddConfigurationsTextInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  orderThreshold: any;
  belowThreshold: any;
  aboveThreshold: any;
  _handle_open_configuration_dialog: any;
}

export const AddConfigurationsText = (props: AddConfigurationsTextInterface) => {
  const { classes, name, multiShipmentLevel, orderThreshold, belowThreshold, aboveThreshold, _handle_open_configuration_dialog } = props;

  const _check_if_parent_configuratopn_present = useCallback(() => {
    if (multiShipmentLevel === false) {
      if (orderThreshold !== "" && belowThreshold !== "" && aboveThreshold !== "") {
        return true;
      }
    }
    return false;
  }, [multiShipmentLevel, orderThreshold, belowThreshold, aboveThreshold]);

  return (
    <Grid container className={classes.addConfigIconMainDivStyle}>
      {_check_if_parent_configuratopn_present() === true ? (
        <Grid item xs={12} className={classes.addConfigIconDivStyle} onClick={(e) => _handle_open_configuration_dialog(name, multiShipmentLevel)}>
          <AddConfigurationsCommonTextUIView name={name} classes={classes} />
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.addConfigIconDivStyleDisabled}>
          <AddConfigurationsCommonTextUIView name={name} classes={classes} />
        </Grid>
      )}
    </Grid>
  );
};

interface AddThresoldRangeTextInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  orderThreshold: any;
  belowThreshold: any;
  aboveThreshold: any;
  _handle_click_add_threshold_range: any;
}

export const AddThresoldRangeText = (props: AddThresoldRangeTextInterface) => {
  const { classes, name, multiShipmentLevel, orderThreshold, belowThreshold, aboveThreshold, _handle_click_add_threshold_range } = props;

  const _check_if_parent_configuratopn_present = () => {
    if (multiShipmentLevel === false) {
      if (orderThreshold !== "" && belowThreshold !== "" && aboveThreshold !== "") {
        return true;
      }
    }
    return false;
  };
  return (
    <Grid container className={classes.addConfigIconMainDivStyle}>
      {_check_if_parent_configuratopn_present() === true ? (
        <Grid item xs={12} className={classes.addInnerConfigIconDivStyle} onClick={(e) => _handle_click_add_threshold_range(name, multiShipmentLevel)}>
          <AddCircleOutlineIcon className={classes.addConfigIconStyle} />
          <Typography className={classes.addConfigTextStyle}>Add threshold range</Typography>
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.addInnerConfigIconDivStyleDisabled}>
          <Tooltip title="Add threshold range">
            <img src={AddPlusIcon} alt="AddPlusIcon" className={classes.addThresholdRangeConfigIconStyle} />
          </Tooltip>
        </Grid>
      )}
    </Grid>
    // <Grid container className={classes.addConfigIconMainDivStyle}>
    //   {_check_if_parent_configuratopn_present() === true ? (
    //     <Grid item xs={12} className={classes.addInnerConfigIconDivStyle} onClick={(e) => _handle_click_add_threshold_range(name, multiShipmentLevel)}>
    //       <AddCircleOutlineIcon className={classes.addThresholdRangeConfigIconStyle} />
    //       <Typography className={classes.addThresholdRangeConfigTextStyle}>Add threshold range</Typography>
    //     </Grid>
    //   ) : (
    //     <Grid item xs={12} className={classes.addInnerConfigIconDivStyleDisabled}>
    //       <AddCircleOutlineIcon className={classes.addThresholdRangeConfigIconStyle} />
    //       <Typography className={classes.addThresholdRangeConfigTextStyle}>Add threshold range</Typography>
    //     </Grid>
    //   )}
    // </Grid>
  );
};

interface AddSubtractThresholdRangeObjectButtonsInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  rangeObject: any;
  _handle_click_delete_threshold_range: any;
  _handle_click_add_threshold_range: any;
}

export const AddSubtractThresholdRangeObjectButtons = (props: AddSubtractThresholdRangeObjectButtonsInterface) => {
  const { classes, name, multiShipmentLevel, rangeObject, _handle_click_delete_threshold_range, _handle_click_add_threshold_range } = props;
  return (
    <>
      <img src={SubtractMinusIcon} alt="SubtractMinusIcon" className={classes.actionIconStyle} onClick={(e) => _handle_click_delete_threshold_range(name, multiShipmentLevel)} />
      {rangeObject.newOrderThreshold !== "" && Number(rangeObject.newOrderThreshold) > Number(rangeObject.previousOrderThreshold) && (
        <img src={AddPlusIcon} alt="AddPlusIcon" className={classes.actionIconStyle} onClick={(e) => _handle_click_add_threshold_range(name, multiShipmentLevel)} />
      )}
    </>
  );
};

interface AddCapacityUtilisationRangeTextInterface {
  classes: any;
  _handle_click_add_capacity_range: any;
  capacityHigh: any;
  capacityLow: any;
  deliveryFee: any;
}

export const AddCapacityUtilisationRangeText = (props: AddCapacityUtilisationRangeTextInterface) => {
  const { classes, _handle_click_add_capacity_range, capacityHigh, capacityLow, deliveryFee } = props;

  const _check_if_parent_configuratopn_present = () => {
    if (capacityLow !== "" && capacityHigh !== "" && Number(capacityHigh) < 100 && deliveryFee !== "") {
      return true;
    }
    return false;
  };

  return (
    <Grid container className={classes.addConfigIconMainDivStyle}>
      {_check_if_parent_configuratopn_present() === true ? (
        <Grid item xs={12} className={classes.addConfigIconDivStyle} onClick={(e) => _handle_click_add_capacity_range()}>
          <AddCircleOutlineIcon className={classes.addConfigIconStyle} />
          <Typography className={classes.addConfigTextStyle}>Add Range</Typography>
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.addConfigIconDivStyleDisabled}>
          <AddCircleOutlineIcon className={classes.addConfigIconStyle} />
          <Typography className={classes.addConfigTextStyle}>Add Range</Typography>
        </Grid>
      )}
    </Grid>
  );
};

interface AddSubtractCapacityRangeObjectButtonsInterface {
  classes: any;
  _handle_click_add_capacity_range: any;
  capacityHigh: any;
  capacityLow: any;
  deliveryFee: any;
  state_index: any;
  _handle_click_remove_capacity_range: any;
}

export const AddSubtractCapacityRangeObjectButtons = (props: AddSubtractCapacityRangeObjectButtonsInterface) => {
  const { classes, _handle_click_add_capacity_range, capacityHigh, capacityLow, deliveryFee, _handle_click_remove_capacity_range, state_index } = props;
  return (
    <>
      <img src={SubtractMinusIcon} alt="SubtractMinusIcon" className={classes.actionIconStyle} onClick={(e) => _handle_click_remove_capacity_range(state_index)} />
      {capacityHigh !== "" && capacityLow !== "" && Number(capacityLow) < 100 && deliveryFee !== "" && (
        <img src={AddPlusIcon} alt="AddPlusIcon" className={classes.actionIconStyle} onClick={_handle_click_add_capacity_range} />
      )}
    </>
  );
};

interface ChangeConfigOtionProps {
  is_edit_mode: any;
  classes: any;
  use_existing_config_change_handler: any;
  use_existing_checked: any;
  change_existing_checked: any;
  use_change_existing_config_change_handler: any;
}

export const ChangeConfigOtion = (props: ChangeConfigOtionProps) => {
  const { classes, is_edit_mode, use_existing_config_change_handler, use_existing_checked, change_existing_checked, use_change_existing_config_change_handler } = props;
  return (
    <Grid item xs={12} className={classes.dFlexEnd}>
      {is_edit_mode ? (
        <FormControlLabel
          control={<Checkbox className={classes.switchStyle} checked={change_existing_checked} onChange={use_change_existing_config_change_handler} />}
          label={<Typography className={classes.dlvFeeCalculationTextStyle}>Change existing configuration</Typography>}
        />
      ) : (
        <FormControlLabel
          control={<Checkbox className={classes.switchStyle} checked={use_existing_checked} onChange={use_existing_config_change_handler} />}
          label={<Typography className={classes.dlvFeeCalculationTextStyle}>Use existing configuration</Typography>}
        />
      )}
    </Grid>
  );
};

interface ThresholdRangeTooltipViewUtilInterface {
  classes: any;
  orderThreshold: string | number;
  aboveThreshold: string | number;
  belowThreshold: string | number;
  deliveryFeeRangeConfigDTOList: any;
}
export const ThresholdRangeTooltipViewUtil = (props: ThresholdRangeTooltipViewUtilInterface) => {
  const { classes, orderThreshold, aboveThreshold, belowThreshold, deliveryFeeRangeConfigDTOList } = props;
  return (
    <Card>
      <CardContent className={classes.thresholdRangeTooltipCardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>Greater than or Equals to (Threshold Value)</TableCell>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>Less than (Threshold Value)</TableCell>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>Delivery Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deliveryFeeRangeConfigDTOList !== undefined &&
              Array.isArray(deliveryFeeRangeConfigDTOList) &&
              deliveryFeeRangeConfigDTOList.length > 0 &&
              deliveryFeeRangeConfigDTOList.map((fee: any, fee_index: any) => (
                <TableRow key={`${fee_index}-deliveryFeeRangeConfigDTOList-delivery-Fee`}>
                  <TableCell className={classes.thresholdRangeTooltipTableCell}>{fee_index === 0 ? "" : deliveryFeeRangeConfigDTOList[fee_index - 1]?.orderThreshold || ""}</TableCell>
                  <TableCell className={classes.thresholdRangeTooltipTableCell}>{fee.orderThreshold || ""}</TableCell>
                  <TableCell className={classes.thresholdRangeTooltipTableCell}>{fee.deliveryFee || ""}</TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{`${deliveryFeeRangeConfigDTOList[deliveryFeeRangeConfigDTOList?.length - 1]?.orderThreshold}`}</TableCell>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{orderThreshold}</TableCell>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{`${belowThreshold}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{orderThreshold}</TableCell>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{""}</TableCell>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{`${aboveThreshold}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

interface ServiceFeeTooltipViewUtilInterface {
  classes: any;
  dynamicFeeRange: any;
}
export const ServiceFeeTooltipViewUtil = (props: ServiceFeeTooltipViewUtilInterface) => {
  const { classes, dynamicFeeRange } = props;
  return (
    <Card>
      <CardContent className={classes.thresholdRangeTooltipCardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>Fee Percentage</TableCell>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>Min Value</TableCell>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>Max Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dynamicFeeRange !== undefined &&
              Array.isArray(dynamicFeeRange) &&
              dynamicFeeRange.length > 0 &&
              dynamicFeeRange.map((fee: any, fee_index: any) => (
                <TableRow key={`${fee_index}-dynamicFeeRange-service-Fee`}>
                  <TableCell className={classes.thresholdRangeTooltipTableCell}>{fee.feePercentage || ""}</TableCell>
                  <TableCell className={classes.thresholdRangeTooltipTableCell}>{fee.minValue || ""}</TableCell>
                  <TableCell className={classes.thresholdRangeTooltipTableCell}>{fee.maxValue || ""}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

interface SlotCapacityTooltipViewUtilInterface {
  classes: any;
  deliveryFeeSlotCapacityConfigDTOList: SlotCapacityViewUtilisationConfigurationInterface[];
}
export const SlotCapacityTooltipViewUtil = (props: SlotCapacityTooltipViewUtilInterface) => {
  const { classes, deliveryFeeSlotCapacityConfigDTOList } = props;

  return (
    <Card>
      <CardContent className={classes.thresholdRangeTooltipCardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{`${DELIVERY_FEE_TEXT_CONSTANT.RANGE_START_GREATER_THAN_OR_EQUAL_TO}`}</TableCell>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{`${DELIVERY_FEE_TEXT_CONSTANT.RANGE_END_LESS_THAN_}`}</TableCell>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{`${DELIVERY_FEE_TEXT_CONSTANT.DELIVERY_FEES}`}</TableCell>{" "}
            </TableRow>
          </TableHead>
          <TableBody>
            {deliveryFeeSlotCapacityConfigDTOList.map((fee: SlotCapacityViewUtilisationConfigurationInterface, feeIndex: number) => (
              <TableRow key={`${feeIndex}-deliveryFeeRangeConfigDTOList-delivery-Fee`}>
                <TableCell className={classes.thresholdRangeTooltipTableCell}>{!isUndefined(fee.capacityLow) ? fee.capacityLow : ""}</TableCell>
                <TableCell className={classes.thresholdRangeTooltipTableCell}>{fee.capacityHigh || ""}</TableCell>
                <TableCell className={classes.thresholdRangeTooltipTableCell}>{!isUndefined(fee.deliveryFee) ? fee.deliveryFee : ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export const getPromiseTimeInHourAndMinutes = (minutes: number) => `${Math.floor(minutes / 60)} hour ${minutes % 60} mins`;
export const getPromiseTimeInMinutes = (minutes: number) => `${minutes} mins`;

export const convertToMinutes = (maxTimeToSlot: any) => {
  let minutes: number = 0;
  if (!isUndefined(maxTimeToSlot)) {
    minutes = Math.floor(Number(maxTimeToSlot) / 60);
  }
  return getPromiseTimeInMinutes(minutes);
};

interface SlotProximityFeeTooltipViewUtilInterface {
  classes: any;
  slotProximityFeeConfigDTOList: slotProximityFeeConfigDTOListInterface[];
}

export const SlotProximityFeeTooltipViewUtil = (props: SlotProximityFeeTooltipViewUtilInterface) => {
  const { classes, slotProximityFeeConfigDTOList } = props;

  return (
    <Card>
      <CardContent className={classes.thresholdRangeTooltipCardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{`${DELIVERY_FEE_TEXT_CONSTANT.PROXIMITY_TO_SLOT}`}</TableCell>
              {/* <TableCell className={classes.thresholdRangeTooltipTableCell}>{`${DELIVERY_FEE_TEXT_CONSTANT.PROXIMITY_TO_SLOT_MAX_TIME}`}</TableCell> */}
              <TableCell className={classes.thresholdRangeTooltipTableCell}>{`${DELIVERY_FEE_TEXT_CONSTANT.SLOT_PROXIMITY_FEE_TITLE}`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slotProximityFeeConfigDTOList.map((obj: slotProximityFeeConfigDTOListInterface, objIndex: number) => (
              <TableRow key={`${objIndex}-slotProximityFeeConfigDTOList-slot-Proximity-Fee`}>
                {/* <TableCell className={classes.thresholdRangeTooltipTableCell}>{!isUndefined(obj.minTimeToSlot) ? convertToMinutes(obj.minTimeToSlot) : ""}</TableCell> */}
                <TableCell className={classes.thresholdRangeTooltipTableCell}>{!isUndefined(obj.maxTimeToSlot) ? convertToMinutes(obj.maxTimeToSlot) : ""}</TableCell>
                <TableCell className={classes.thresholdRangeTooltipTableCell}>{!isUndefined(obj.deliveryFee) ? obj.deliveryFee : ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
