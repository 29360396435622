export interface AddUpdateUserState {
  firstName: string;
  lastName: string;
  emailId: string;
  role: string;
  serviceTypeId: Array<any>;
  country: string;
  countryId: Array<string>;
  status: string;
  featureId: Array<any>;
  id: any;
  userDataRecord: any;
  active: string;
  createdAt: any;
  createdBy: any;
  lastModifiedAt: "";
  lastModifiedBy: "";
}

export const AddUpdateUserInitialState: AddUpdateUserState = {
  firstName: "",
  lastName: "",
  emailId: "",
  role: "",
  serviceTypeId: [],
  country: "",
  countryId: [],
  status: "Active",
  featureId: [],
  id: "",
  userDataRecord: [],
  active: "",
  createdAt: "",
  createdBy: "",
  lastModifiedAt: "",
  lastModifiedBy: "",
};

export interface AddUpdateUserError {
  firstName: string;
  lastName: string;
  emailId: string;
  role: string;
  serviceTypeId: string;
  country: string;
  countryId: string;
  status: string;
  featureId: string;
}

export const AddUpdateUserErrorState: AddUpdateUserError = {
  firstName: "",
  lastName: "",
  emailId: "",
  role: "",
  serviceTypeId: "",
  country: "",
  countryId: "",
  status: "",
  featureId: "",
};

export interface SelectItemProps {
  id: string;
  name: string;
}

export const statusListData = [
  { id: "Active", name: "Active" },
  { id: "In Active", name: "In Active" },
];

export const ACTIVE_STATUS = "Active";
export const IN_ACTIVE_STATUS = "In Active";
