import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import lodashGet from "lodash.get";

import { API_RESPONSE_STATUS, DELIVERY_PROMISE_VEHICLE_TYPE_LIST, SERVICE_TYPES } from "../../../config/constants";
import { selectServiceTypes } from "../../../config/redux/configurationsSlice";
import { generateCustomVehicleTypeList, generateDropdownItems, generateDropdownWithMentionedIntervalItems } from "../../../utils/helperFunctions";
import { fetchPOSZoneMappingList, resetPOSZoneMappingList, selectServicesPOSZoneMappingList } from "../redux/ruleEngineSlice";
import { AUTOMATED_PROMISE_CONFIG, getUniqueList, PROMISE_TYPE, RULE_ENGINE_TEXT_CONSTANT } from "../RuleEngineUtils";

import TransferList from "../../common/TransferList/TransferList";
import commonStyles from "../RuleEngineCommonStyles";
import serviceRuleEngineStyles from "./ServiceRuleEngineStyles";

import RenderTypeOfPromiseCard from "./common/RenderTypeOfPromiseCard";
import RenderOrderVelocity from "./common/RenderOrderVelocity";
import RenderWeightRule from "./common/RenderWeightRule";
import RenderItemCountRule from "./common/RenderItemCountRule";
import RenderScalableItems from "./common/RenderScalableItems";
import RenderAutomatedDeliveryPromiseRule from "./common/RenderAutomatedDeliveryPromiseRule";
import RenderMinimumPromiseTypeConfiguration from "./common/RenderMinimumPromiseTypeConfiguration";
import RenderMaximumPromiseTypeConfiguration from "./common/RenderMaximumPromiseTypeConfiguration";
import RenderMaximumPromiseToDisplayTypeConfiguration from "./common/RenderMaximumPromiseToDisplayTypeConfiguration";

export const DEFAULT_TIME_HOURS = generateDropdownItems(0, 3, "hours");
export const DEFAULT_TIME_MINUTES = generateDropdownItems(1, 59, "minutes");
export const ITEM_COUNT_INCREMENTAL_SECONDS = generateDropdownItems(15, 150, "seconds");
export const ITEMS_INCREMENTAL_TIME_RANGE = generateDropdownItems(1, 30, "minutes");
export const TIME_RANGE_INCREMENTAL_MINUTES = generateDropdownWithMentionedIntervalItems(30, 300, 30, "minutes");
export const EXTRA_PROMISE_INCREMENTAL_MINUTES = generateDropdownItems(1, 59, "minutes");
export const HARD_CODED_VEHICLE_TYPE_LIST = generateCustomVehicleTypeList(DELIVERY_PROMISE_VEHICLE_TYPE_LIST);
export const MAXIMUM_PROMISE_TIME_HOURS = generateDropdownItems(0, 24, "hours");
export const MAXIMUM_PROMISE_TIME_MINUTES = generateDropdownItems(0, 59, "minutes");

const ServiceRuleEngine = ({
  serviceName = "",
  config,
  handlePromiseTypeCheckboxChange, // Promise Type Change
  // Minimum Promise Time
  onMinTimeIsSameAsEarlierChange,
  handleMinTimeConfigEdit,
  onMinTimeChange,
  // Maximum Promise Time
  onMaxTimeIsSameAsEarlierChange,
  handleMaxTimeConfigEdit,
  isAddMaximumDeliveryPromiseOtherDaysButtonDisabled,
  onAddMaximumDeliveryPromiseOtherDays,
  onMaximumDeliveryPromiseFieldsChange,
  onRemoveMaximumDeliveryPromiseOtherDays,
  handleMaxPromiseTimeConfigEdit,
  // Maximum Promise Time For Visibility
  handleMaxPromiseTimeForVisibilityConfigEdit,
  onMaxTimeForVisibilityIsSameAsEarlierChange,
  handleMaxTimeForVisibilityConfigEdit,
  onMaximumDeliveryPromiseForVisibilityFieldsChange,
  //
  onUseForConfigurationSwitchChange,
  onIsSameAsEarlierCheckboxChange,
  onWeightRuleFieldsChange,
  onCountRuleFieldsChange,
  onAddScalableItems,
  onRemoveScalableItems,
  onScalableItemsFieldChange,
  onPOSZonesSelected,
  onWeightRuleConfigEdit,
  onCountRuleConfigEdit,
  onScaleRuleConfigEdit,
  resetTransferList,
  // Order Velocity
  handleOrderVelocityIsSameAsEarlierChange,
  handleOrderVelocityConfigEdit,
  onOrderVelocityFieldsChange,
  handleOrderVelocityRuleConfigEdit,
  isAddOtherDaysButtonDisabled,
  onAddOtherDays,
  onRemoveOtherDays,
  // Type of Vehicle
  handleTypeOfVehicleConfigEdit,
  handleTypeOfVehicleIsSameAsEarlierChange,
  handleTypeOfVehicleRuleConfigEdit,
  handleVehicleTypeConfigActiveCheckbox,
  onTypeOfVehicleFromToFieldsChange,
  // Automated Promise
  handleAutomatedRuleConfigEdit,
  onAutomatedPromiseFieldsChange,
}) => {
  const isServiceExpress = !!(serviceName.toUpperCase() === SERVICE_TYPES.EXPRESS);
  const commonClasses = commonStyles();
  const classes = serviceRuleEngineStyles();
  const [resetZoneList, setResetZoneList] = useState(false);
  const [apiParams, setAPIParams] = useState({ currentPage: 0, searchedText: "" });

  const dispatch = useDispatch();

  const {
    currentCountry: { countryId },
  } = useSelector((state) => state.appConfig);
  const serviceTypes = useSelector(selectServiceTypes);

  const { fetchStatus: posZoneMappingFetchStatus, data: posZoneMappingData } = useSelector((state) => selectServicesPOSZoneMappingList(state, serviceName));

  const handleItemsAssignedChange = (zonesSelected) => {
    onPOSZonesSelected(serviceName, zonesSelected);
  };

  const getServiceTypeId = useCallback(() => {
    let id = 0;
    if (serviceTypes && serviceTypes.length) {
      id = serviceTypes.find((st) => st.name === serviceName).id;
    }
    return id;
  }, [serviceTypes, serviceName]);

  const callPOSZoneMappingListAPI = useCallback(() => {
    dispatch(
      fetchPOSZoneMappingList({
        countryId,
        serviceTypeId: getServiceTypeId(),
        serviceTypeName: serviceName,
        query: apiParams.searchedText,
        pageNumber: apiParams.currentPage,
        dynamicPromiseType: config.dynamicPromise,
      })
    );
    setResetZoneList((prev) => false);
  }, [dispatch, countryId, getServiceTypeId, serviceName, apiParams.currentPage, apiParams.searchedText, config.dynamicPromise]);

  const handlePOSZoneMappingSearch = (query) => {
    if (query !== apiParams.searchedText) {
      setAPIParams((prev) => ({ ...prev, searchedText: query, currentPage: 0 }));
      dispatch(resetPOSZoneMappingList({ serviceTypeName: serviceName }));
    }
  };

  function fetchMoreData() {
    if (posZoneMappingData && posZoneMappingData.list && posZoneMappingData.list.length > 0) {
      setAPIParams((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  }

  const renderMinimumPromiseTypeConfiguration = () => {
    return (
      <RenderMinimumPromiseTypeConfiguration
        classes={classes}
        config={config}
        onMinTimeIsSameAsEarlierChange={onMinTimeIsSameAsEarlierChange}
        handleMinTimeConfigEdit={handleMinTimeConfigEdit}
        onMinTimeChange={onMinTimeChange}
      />
    );
  };

  const renderMaximumPromiseTypeConfiguration = () => {
    return (
      <>
        <RenderMaximumPromiseTypeConfiguration
          classes={classes}
          config={config}
          onMaxTimeIsSameAsEarlierChange={onMaxTimeIsSameAsEarlierChange}
          handleMaxTimeConfigEdit={handleMaxTimeConfigEdit}
          isAddMaximumDeliveryPromiseOtherDaysButtonDisabled={isAddMaximumDeliveryPromiseOtherDaysButtonDisabled}
          onAddMaximumDeliveryPromiseOtherDays={onAddMaximumDeliveryPromiseOtherDays}
          onMaximumDeliveryPromiseFieldsChange={onMaximumDeliveryPromiseFieldsChange}
          onRemoveMaximumDeliveryPromiseOtherDays={onRemoveMaximumDeliveryPromiseOtherDays}
          handleMaxPromiseTimeConfigEdit={handleMaxPromiseTimeConfigEdit}
        />
        <RenderMaximumPromiseToDisplayTypeConfiguration
          classes={classes}
          config={config}
          handleMaxPromiseTimeForVisibilityConfigEdit={handleMaxPromiseTimeForVisibilityConfigEdit}
          onMaxTimeForVisibilityIsSameAsEarlierChange={onMaxTimeForVisibilityIsSameAsEarlierChange}
          handleMaxTimeForVisibilityConfigEdit={handleMaxTimeForVisibilityConfigEdit}
          onMaximumDeliveryPromiseForVisibilityFieldsChange={onMaximumDeliveryPromiseForVisibilityFieldsChange}
        />
      </>
    );
  };

  const renderStaticAndDynamicPromise = () => {
    return (
      <>
        {/* <RenderTypeOfVehicle
          classes={classes}
          config={config}
          lodashGet={lodashGet}
          handleTypeOfVehicleConfigEdit={handleTypeOfVehicleConfigEdit}
          handleTypeOfVehicleIsSameAsEarlierChange={handleTypeOfVehicleIsSameAsEarlierChange}
          handleTypeOfVehicleRuleConfigEdit={handleTypeOfVehicleRuleConfigEdit}
          handleVehicleTypeConfigActiveCheckbox={handleVehicleTypeConfigActiveCheckbox}
          onTypeOfVehicleFromToFieldsChange={onTypeOfVehicleFromToFieldsChange}
        /> */}
      </>
    );
  };

  const renderDynamicPromise = () => {
    return (
      <>
        <RenderOrderVelocity
          classes={classes}
          config={config}
          lodashGet={lodashGet}
          handleOrderVelocityConfigEdit={handleOrderVelocityConfigEdit}
          handleOrderVelocityIsSameAsEarlierChange={handleOrderVelocityIsSameAsEarlierChange}
          handleOrderVelocityRuleConfigEdit={handleOrderVelocityRuleConfigEdit}
          onOrderVelocityFieldsChange={onOrderVelocityFieldsChange}
          isAddOtherDaysButtonDisabled={isAddOtherDaysButtonDisabled}
          onAddOtherDays={onAddOtherDays}
          onRemoveOtherDays={onRemoveOtherDays}
        />
        <RenderWeightRule
          classes={classes}
          config={config}
          serviceName={serviceName}
          onUseForConfigurationSwitchChange={onUseForConfigurationSwitchChange}
          onIsSameAsEarlierCheckboxChange={onIsSameAsEarlierCheckboxChange}
          onWeightRuleConfigEdit={onWeightRuleConfigEdit}
          onWeightRuleFieldsChange={onWeightRuleFieldsChange}
        />
        <RenderItemCountRule
          classes={classes}
          config={config}
          lodashGet={lodashGet}
          serviceName={serviceName}
          onUseForConfigurationSwitchChange={onUseForConfigurationSwitchChange}
          onIsSameAsEarlierCheckboxChange={onIsSameAsEarlierCheckboxChange}
          onCountRuleConfigEdit={onCountRuleConfigEdit}
          onCountRuleFieldsChange={onCountRuleFieldsChange}
        />
        <RenderScalableItems
          classes={classes}
          config={config}
          serviceName={serviceName}
          onUseForConfigurationSwitchChange={onUseForConfigurationSwitchChange}
          onIsSameAsEarlierCheckboxChange={onIsSameAsEarlierCheckboxChange}
          onScaleRuleConfigEdit={onScaleRuleConfigEdit}
          onScalableItemsFieldChange={onScalableItemsFieldChange}
          getUniqueList={getUniqueList}
          onRemoveScalableItems={onRemoveScalableItems}
          onAddScalableItems={onAddScalableItems}
        />
      </>
    );
  };

  const renderAutomatedPromise = () => {
    return (
      <>
        <RenderAutomatedDeliveryPromiseRule
          classes={classes}
          serviceName={serviceName}
          title={RULE_ENGINE_TEXT_CONSTANT.LAST_MILE_TIME_AUTOMATED_PROMISE}
          config={config}
          keyName={AUTOMATED_PROMISE_CONFIG.LAST_MILE_TIME_AUTOMATED_CONFIG}
          promiseRuleEdit={config.enableLastMileTimeAutomatedConfigPromiseRuleEdit}
          inputHourName={"lastMileTimeHour"}
          hourValue={config.lastmileTimeAutomatedConfig.lastMileTimeHour}
          inputMinName={"lastMileTimeMin"}
          minValue={config.lastmileTimeAutomatedConfig.lastMileTimeMin}
          onUseForConfigurationSwitchChange={onUseForConfigurationSwitchChange}
          onIsSameAsEarlierCheckboxChange={onIsSameAsEarlierCheckboxChange}
          handleAutomatedRuleConfigEdit={handleAutomatedRuleConfigEdit}
          onAutomatedPromiseFieldsChange={onAutomatedPromiseFieldsChange}
        />
      </>
    );
  };

  useEffect(() => {
    if (serviceTypes.length) {
      dispatch(resetPOSZoneMappingList({ serviceTypeName: serviceName }));
      callPOSZoneMappingListAPI();
    }
  }, [serviceName, serviceTypes, callPOSZoneMappingListAPI]);

  useEffect(() => {
    return () => {
      dispatch(resetPOSZoneMappingList({ serviceTypeName: serviceName }));
    };
  }, [dispatch, serviceName]);

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded={isServiceExpress} className={commonClasses.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={commonClasses.accordionSummary}>
          {serviceName}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <RenderTypeOfPromiseCard classes={classes} config={config} handlePromiseTypeCheckboxChange={handlePromiseTypeCheckboxChange} />
            </Grid>
          </Grid>
          {renderMinimumPromiseTypeConfiguration()}
          {(config.type === PROMISE_TYPE.STATIC || config.type === PROMISE_TYPE.DYNAMIC) && renderStaticAndDynamicPromise()}
          {config.type === PROMISE_TYPE.DYNAMIC && renderDynamicPromise()}
          {config.type === PROMISE_TYPE.AUTOMATED && renderAutomatedPromise()}
          {(config.type === PROMISE_TYPE.AUTOMATED || config.type === PROMISE_TYPE.DYNAMIC) && renderMaximumPromiseTypeConfiguration()}
          {!config.isEditMode && (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TransferList
                  title="POS-Zone Mapping"
                  fetchingList={posZoneMappingFetchStatus === API_RESPONSE_STATUS.LOADING}
                  onLeftItemsSearch={handlePOSZoneMappingSearch}
                  leftSideItems={lodashGet(posZoneMappingData, "list", [])}
                  hasMoreData={!lodashGet(posZoneMappingData, "posAndZoneDetails.empty", false)}
                  onFetchMore={fetchMoreData}
                  onItemsAssigned={handleItemsAssignedChange}
                  resetItem={resetTransferList || resetZoneList}
                />
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ServiceRuleEngine;
