import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Grid, Typography, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";

import { APP_ROUTES } from "../../../config/constants";
import { selectAllAreas, selectAllRegions } from "../../../config/redux/configurationsSlice";
import { getFullAddress, getRegionNameFromRegionId } from "../../../utils/helperFunctions";
import { is_basic_details_edit_visible } from "../CompleteSetupUtils";
import { ViewBasicDetailNameAndValue } from "../CompleteSetupViewUtils";

import ExpandMoreIcon from "../../assets/svg/ExpandAccordionIcon.svg";
import EditIcon from "../../assets/svg/Pencil.svg";
import Loader from "../../common/Loader/Loader";

import basicInformationStyles from "./BasicInformationStyles";

const BasicInformation = ({ loading, posDetails, isEditable = true, userInfo = {} }) => {
  const { storeName, posType, posNo, services, posContacts, posAddress } = posDetails;

  const classes = basicInformationStyles();

  const regions = useSelector(selectAllRegions);
  const areas = useSelector(selectAllAreas);

  const getAreaNameFromAreaId = (regionId, areaId) => {
    let areaObj = areas.find((a) => a.id === parseInt(areaId));
    return areaObj ? areaObj.name : "";
  };

  const renderServices = () => {
    return services && services.length ? services.map((s) => s.name).join(", ") : "";
  };

  return (
    <Accordion defaultExpanded className={classes.accordion}>
      <AccordionSummary expandIcon={<img src={ExpandMoreIcon} alt="Expand Accordion Icon" />} className={classes.accordionSummary}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            Basic Details
          </Grid>
          {isEditable && is_basic_details_edit_visible(userInfo) && (
            <Grid item xs={2}>
              <Grid container>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                  <Link to={`${APP_ROUTES.CREATE_POS}${APP_ROUTES.BASIC_DETAILS}/${posNo}`} className={classes.dFlex}>
                    <img src={EditIcon} alt="Expand Accordion Icon" />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Grid container>
              <Grid item>
                <Typography className={classes.primaryInfoTitle}>Primary Information</Typography>
              </Grid>
              <Grid container className={classes.informationContainer}>
                <ViewBasicDetailNameAndValue xsValue={3} classes={classes} fieldTitle={"Store Name"} fieldData={storeName} />
                <ViewBasicDetailNameAndValue xsValue={3} classes={classes} fieldTitle={"POS Type"} fieldData={posType} />
                <ViewBasicDetailNameAndValue xsValue={2} classes={classes} fieldTitle={"POS Number"} fieldData={posNo} />
                <ViewBasicDetailNameAndValue xsValue={4} classes={classes} fieldTitle={"Service Types"} fieldData={services && renderServices()} />
              </Grid>
              <div className={classes.horizontalLine} />
              <Grid container>
                <Grid item>
                  <Typography className={classes.primaryInfoTitle}>Address Information</Typography>
                </Grid>
                <Grid container className={classes.informationContainer}>
                  <ViewBasicDetailNameAndValue
                    xsValue={3}
                    classes={classes}
                    fieldTitle={"Name"}
                    fieldData={posContacts !== undefined && posContacts.length > 0 && posContacts[0] !== undefined && posContacts[0].name !== undefined ? posContacts[0].name : ""}
                  />
                  <ViewBasicDetailNameAndValue
                    xsValue={3}
                    classes={classes}
                    fieldTitle={"Email"}
                    fieldData={posContacts !== undefined && posContacts.length > 0 && posContacts[0] !== undefined && posContacts[0].email !== undefined ? posContacts[0].email : ""}
                  />
                  <ViewBasicDetailNameAndValue
                    xsValue={2}
                    classes={classes}
                    fieldTitle={"Phone Number"}
                    fieldData={posContacts !== undefined && posContacts.length > 0 && posContacts[0] !== undefined && posContacts[0].phoneNumber !== undefined ? posContacts[0].phoneNumber : ""}
                  />
                  <ViewBasicDetailNameAndValue
                    xsValue={4}
                    classes={classes}
                    fieldTitle={"Coordinates"}
                    fieldData={
                      posAddress &&
                      getFullAddress({
                        ...posAddress,
                        region: getRegionNameFromRegionId(posAddress?.region, regions),
                        area: getAreaNameFromAreaId(posAddress?.region, posAddress?.area),
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default BasicInformation;
