import React from "react";

import { Grid, Typography } from "@material-ui/core";

import SelectBox from "../../../common/SelectBox/SelectBox";
import SwitchButton from "../../../common/SwitchButton/SwitchButton";

import { DEFAULT_RULE_CONFIG } from "../../RuleEngineUtils";
import { ChangeConfigOtion } from "../ServiceRuleEngineUtils";
import { MAXIMUM_PROMISE_TIME_HOURS, MAXIMUM_PROMISE_TIME_MINUTES } from "../ServiceRuleEngine";
import { HEADER_NAME_CONSTANT } from "../../CurrentConfiguration/CurrentConfigConstant";

interface RenderMaximumPromiseToDisplayTypeConfigurationInterface {
  classes: any;
  config: DEFAULT_RULE_CONFIG;
  //
  handleMaxPromiseTimeForVisibilityConfigEdit: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onMaxTimeForVisibilityIsSameAsEarlierChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMaxTimeForVisibilityConfigEdit: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onMaximumDeliveryPromiseForVisibilityFieldsChange: (name: string, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RenderMaximumPromiseToDisplayTypeConfiguration = (props: RenderMaximumPromiseToDisplayTypeConfigurationInterface) => {
  const {
    classes,
    config,
    handleMaxPromiseTimeForVisibilityConfigEdit,
    onMaxTimeForVisibilityIsSameAsEarlierChange,
    handleMaxTimeForVisibilityConfigEdit,
    onMaximumDeliveryPromiseForVisibilityFieldsChange,
  } = props;

  return (
    <Grid container className={classes.ruleContainer} spacing={0}>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.ruleLabel}>
            {`${HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME_FOR_VISIBILITY}`}
          </Grid>
          {!config.promiseConfig.maxTimeToDisplaySameAsEarlier && (
            <>
              <Grid item xs={6} className={classes.dFlexEnd}>
                {`${HEADER_NAME_CONSTANT.USE_THIS_VALUE_IN_CALCULATION}`}
              </Grid>
              <Grid item xs={1}>
                <SwitchButton
                  className={config.isEditMode && !config.promiseConfig.enableMaxTimeToDisplayEdit ? classes.disableSwitch : ""}
                  size="small"
                  checked={config.promiseConfig.maxToDisplayActive}
                  disabled={config.isEditMode && !config.promiseConfig.enableMaxTimeToDisplayEdit}
                  handleChange={handleMaxPromiseTimeForVisibilityConfigEdit}
                  changeExisting={config.promiseConfig.enableMaxTimeToDisplayEdit}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <ChangeConfigOtion
        config={config}
        checked={config.promiseConfig.maxTimeToDisplaySameAsEarlier}
        classes={classes}
        xsValue={4}
        checkChangeHandler={onMaxTimeForVisibilityIsSameAsEarlierChange}
        changeExisting={config.promiseConfig.enableMaxTimeToDisplayEdit}
        onConfigEdit={handleMaxTimeForVisibilityConfigEdit}
      />

      {config.promiseConfig.maxToDisplayActive && (
        <Grid item xs={12} className={classes.ruleEditingDivStyle}>
          <Grid container>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography className={classes.orderVelocityRuleHeaderConfigurationStyle}>{`${HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME_HOUR}`}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.orderVelocityRuleHeaderConfigurationStyle}>{`${HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME_MINUTES}`}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.orderVelocityRuleConfigurationStyle}>
              <Grid item xs={3}>
                <SelectBox
                  fieldLabel=""
                  name="maxTimeHourToDisplay"
                  label="Hour"
                  value={config.promiseConfig.maxTimeHourToDisplay}
                  handleChange={(e: any) => onMaximumDeliveryPromiseForVisibilityFieldsChange("maxTimeHourToDisplay", e)}
                  menuItems={MAXIMUM_PROMISE_TIME_HOURS}
                  disabled={config.isEditMode && !config.promiseConfig.enableMaxTimeToDisplayEdit}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectBox
                  fieldLabel=""
                  name="maxTimeMinutesToDisplay"
                  label="Minute"
                  value={config.promiseConfig.maxTimeMinutesToDisplay}
                  handleChange={(e: any) => onMaximumDeliveryPromiseForVisibilityFieldsChange("maxTimeMinutesToDisplay", e)}
                  menuItems={MAXIMUM_PROMISE_TIME_MINUTES}
                  disabled={config.isEditMode && !config.promiseConfig.enableMaxTimeToDisplayEdit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RenderMaximumPromiseToDisplayTypeConfiguration);
