export interface DeliveryFeeInitialStateInterface {
  loading: boolean;
  refresh: boolean;
  searchText: string;
  deliveryFeeListData: any;
  page: any;
  size: any;
  maxRecordPresent: any;
  deliveryFeeHeaderData: any;
}

export const DeliveryFeeInitialState: DeliveryFeeInitialStateInterface = {
  loading: true,
  refresh: false,
  searchText: "",
  deliveryFeeListData: [],
  page: 0,
  size: 40,
  maxRecordPresent: 0,
  deliveryFeeHeaderData: {},
};

export interface thresholdshipmentLevelValueObjectInterface {
  isEditMode: boolean;
  thresholdAmount: any;
  feesBelowThreshold: any;
  feesAboveThreshold: any;
}

export const thresholdshipmentLevelValueObject: thresholdshipmentLevelValueObjectInterface = {
  isEditMode: false,
  thresholdAmount: "",
  feesBelowThreshold: "",
  feesAboveThreshold: "",
};

export interface thresholdmultiShipmentLevelValueObjectInterface {
  isEditMode: boolean;
  thresholdAmount: any;
  feesAboveThreshold: any;
}

export const thresholdmultiShipmentLevelValueObject: thresholdmultiShipmentLevelValueObjectInterface = {
  isEditMode: true,
  thresholdAmount: "",
  feesAboveThreshold: "",
};

export interface DlvFeeConfigObjStateInterface {
  loading: boolean;
  shipmentLevel: {
    express: thresholdshipmentLevelValueObjectInterface;
    eFood: thresholdshipmentLevelValueObjectInterface;
    nonFood: thresholdshipmentLevelValueObjectInterface;
  };
  multiShipmentLevel: {
    expressEFood: thresholdmultiShipmentLevelValueObjectInterface;
    expressNonFood: thresholdmultiShipmentLevelValueObjectInterface;
    expressEfoodNonFood: thresholdmultiShipmentLevelValueObjectInterface;
    eFoodNonFood: thresholdmultiShipmentLevelValueObjectInterface;
  };
}

export const DlvFeeConfigObjState: DlvFeeConfigObjStateInterface = {
  loading: false,
  shipmentLevel: {
    express: { ...thresholdshipmentLevelValueObject },
    eFood: { ...thresholdshipmentLevelValueObject },
    nonFood: { ...thresholdshipmentLevelValueObject },
  },
  multiShipmentLevel: {
    expressEFood: { ...thresholdmultiShipmentLevelValueObject },
    expressNonFood: { ...thresholdmultiShipmentLevelValueObject },
    expressEfoodNonFood: { ...thresholdmultiShipmentLevelValueObject },
    eFoodNonFood: { ...thresholdmultiShipmentLevelValueObject },
  },
};

export interface ShipmentServiceLevelDeliveryFeeInterface {
  sameAsEarlier: boolean;
  active: boolean;
  enableConfigEdit: boolean;
  orderThreshold: string | number;
  belowThreshold: string | number;
  greaterOrderThresold: string | number;
  aboveThreshold: string | number;
  deliveryFeeRangeConfigDTOList: deliveryFeeRangeConfigDTOListInterface[];
  additionalDeliveryFeeConfigDTOList: any;
  showDrawerConfig: boolean;
  updateDrawerConfig: boolean;
  deliveryFeeSlotCapacityConfigDTOList: [];
  isDeliveryFeeSlotCapacityConfigApplicable: boolean;
}

export interface ShipmentServiceLevelServiceFeeInterface {
  sameAsEarlier: boolean;
  active: boolean;
  enableConfigEdit: boolean;
  isDynamic: boolean;
  isErrorText: string | number;
  staticFees: string | number;
  dynamicFees: any;
}

export interface ShipmentServiceLevelMinimumOrderFeeInterface {
  sameAsEarlier: boolean;
  active: boolean;
  enableConfigEdit: boolean;
  movThreshold: string | number;
}

export interface capacityUtilisationConfigurationInterface {
  id?: string | number;
  capacityLow: string | number;
  capacityHigh: string | number;
  deliveryFee: string | number;
  active: boolean;
  enableEdit: boolean;
  capacityLowErrorMessage: string;
  capacityHighErrorMessage: string;
  capacityFeeErrorMessage: string;
}
export interface ShipmentServiceLevelPromiseSlotFeeInterface {
  sameAsEarlier: boolean;
  active: boolean;
  enableConfigEdit: boolean;
  capacityUtilisationConfiguration: capacityUtilisationConfigurationInterface[];
}

export interface SlotProximityFeeConfigDTOListConfigurationInterface {
  id?: string | number;
  minTimeToSlot: string | number;
  maxTimeToSlot: string | number;
  deliveryFee: string | number;
  minTimeToSlotErrorMessage: string;
  maxTimeToSlotErrorMessage: string;
  deliveryFeeErrorMessage: string;
}
export interface ShipmentServiceLevelSlotProximityFeeInterface {
  sameAsEarlier: boolean;
  active: boolean;
  enableConfigEdit: boolean;
  slotProximityFeeConfigDTOList: SlotProximityFeeConfigDTOListConfigurationInterface[];
}
export interface ShipmentServiceLevelInterface {
  shipmentId: string;
  multi_shipment: boolean;
  isEditMode: boolean;
  openAccordian: boolean;
  status: string;
  deliveryFee: ShipmentServiceLevelDeliveryFeeInterface;
  promiseSlotFee: ShipmentServiceLevelPromiseSlotFeeInterface;
  serviceFee: ShipmentServiceLevelServiceFeeInterface;
  minimumOrderValue: ShipmentServiceLevelMinimumOrderFeeInterface;
  slotProximityFee: ShipmentServiceLevelSlotProximityFeeInterface;
}

export interface OrderLevelMovThresholdInterface {
  sameAsEarlier: boolean;
  active: boolean;
  enableConfigEdit: boolean;
  id: string | number;
  movThreshold: string | number;
}

export interface DlvFeeConfigStateInterface {
  loading: boolean;
  stateConfigurationDialog: boolean;
  stateConfigurationStateObject: any;
  shipmentLevel: any;
  multiShipmentLevel: any;
  posZoneSearchText: string;
  assignedPosSearchText: string;
  totalAreaCount: string | number;
  totalAssignedAreaCount: string | number;
  totalRowsCountData: string | number;
  posZoneServiceRecord: any;
  posZoneServiceFilteredRecord: any;
  selectedPosServiceTypeZone: any;
  activeServiceTypeList: any;
  openMultiShipmentLevel: boolean;
  openPosZonMapping: boolean;
  updateConfigPosId: string | number;
  updateConfigZoneName: string | number;
  isShipmentLevelConfigurationView: boolean;
  isMultiShipmentCalculationPercentageBased: boolean;
  // Service Fee
  openExpressServiceFeeDialog: boolean;
  expressServiceDialogServiceName: string | number;
  expressServiceDialogServiceValue: string | number;
  // Order Level Mov Threshold
  openOrderLevelMovThreshold: boolean;
  orderLevelMovThreshold: OrderLevelMovThresholdInterface;
}

export interface selectedPosServiceTypeZoneInterface {
  posNo: string | number;
  serviceType: string | number;
  serviceTypeId: string | number;
  storeName: string | number;
  zone: string | number;
  zoneId: string | number;
  status: string | number | boolean;
}

export interface DynamicFeesInterface {
  id?: string | number;
  feePercentage: string;
  minValue: string;
  maxValue: string;
}

export interface slotProximityFeeConfigDTOListInterface {
  id?: string | number;
  minTimeToSlot: string | number;
  maxTimeToSlot: string | number;
  deliveryFee: string | number;
}

export interface shipmentLevelDeliveryFeeConfigsInterface {
  id?: string | number;
  status: string | number;
  active: boolean;
  changedConfigs: string[];
  orderThreshold: string | number;
  belowThreshold: string | number;
  aboveThreshold: string | number;
  additionalDeliveryFeeConfigDTOList: any;
  deliveryFeeRangeConfigDTOList: deliveryFeeRangeConfigDTOListInterface[];
  showDrawerConfig: boolean;
  updateDrawerConfig: boolean;
  deliveryFeeSlotCapacityConfigDTOList: any;
  isDeliveryFeeSlotCapacityConfigApplicable: boolean;
  serviceFee: {
    id?: string | number;
    sameAsEarlier: boolean;
    isDynamic: boolean;
    staticFees: string | number;
    dynamicFees: DynamicFeesInterface[];
  };
  minimumOrderValue: { id?: string | number; sameAsEarlier: boolean; movThreshold: string | number };
  slotProximityFeeConfigDTOList: slotProximityFeeConfigDTOListInterface[];
}
export interface payloadObjectInterface {
  selectedPosServiceTypeZone: selectedPosServiceTypeZoneInterface[];
  shipmentLevelDeliveryFeeConfigs: { [key: string | number]: shipmentLevelDeliveryFeeConfigsInterface };
}

export interface PosDetails {
  posNo: string;
  serviceType: string;
  serviceTypeId: string;
  posName: string;
  zoneName: string;
  zoneId: string;
  status: string;
}

export interface deliveryFeeRangeConfigDTOListInterface {
  orderThreshold?: string | number;
  previousOrderThreshold?: string | number;
  newOrderThreshold?: string | number;
  deliveryFee?: string | number;
  isValid?: boolean;
  isErrorText?: string;
}

export interface ShipmentObjInterface {
  shipmentId: string;
  shipmentName: string;
  rowSpan: number;
  colSpan: number;
}
export interface DeliveryFeeHeaderDataListInterface {
  id: string;
  posName: string;
  zoneName: string;
  headerRowsValues: ShipmentObjInterface[];
  headerInnerRowsValues: any[];
  actions: string;
}

export interface SlotCapacityViewUtilisationConfigurationInterface {
  id?: string | number;
  active: boolean;
  capacityLow: string | number;
  capacityHigh: string | number;
  deliveryFee: string | number;
}
