import { Collapse } from "@material-ui/core";
import MultiSelectBox from "components/common/MultiSelectBox/MultiSelectBox";
import SelectBox from "components/common/SelectBox/SelectBox";
import { MAF_USER, SUPER_ADMIN, READONLY_USER, APPROVER } from "../../../config/constants";
import React from "react";
import IconArrowClose from "../../assets/svg/IconArrowClose.svg";
import { ACTIVE_STATUS, AddUpdateUserError, AddUpdateUserState } from "../AddUpdateUserInterface";
import { userStyles } from "../Styles/CollapseBoxStyle";
import SwitchButton from "components/common/SwitchButton/SwitchButton";

interface UserProfileDetailsObject {
  openDetails: boolean;
  handleViewClose: () => void;
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  userObj: AddUpdateUserState;
  rolesList: Array<any>;
  statusList: Array<any>;
  countriesList: Array<any>;
  serviceTypeList: Array<any>;
  handleCountryChange: (value: Array<any>) => void;
  handleServiceTypeChange: (value: Array<any>) => void;
  additionalServicesList: Array<any>;
  errors: AddUpdateUserError;
  handleFeatureChange: (value: Array<any>) => void;
}

const UserProfileDetails = (props: UserProfileDetailsObject) => {
  const classes = userStyles();
  const {
    openDetails,
    handleViewClose,
    handleChange,
    userObj,
    rolesList,
    statusList,
    countriesList,
    serviceTypeList,
    handleCountryChange,
    handleServiceTypeChange,
    additionalServicesList,
    errors,
    handleFeatureChange,
  } = props;

  return (
    <div className={classes.mainDetailsDivStyle}>
      <div onClick={handleViewClose} className={`${classes.detailsDivStyle} ${!openDetails ? classes.detailsDivCloseStyle : ""}`}>
        <span className={classes.detailsBoxTitleSpanStyle}>Profile Details</span>
        <img src={IconArrowClose} alt={IconArrowClose} className={`${classes.openArrowStyle} ${!openDetails ? classes.rotateBy270 : ""}`} />
      </div>
      <Collapse in={openDetails} unmountOnExit className={classes.profileCollapseMainDivStyle} timeout={2}>
        <div className={classes.fieldStatusDivStyle}>
          <span className={classes.fieldTitleSpanStyle}>Status</span>
          <SwitchButton
            leftLabel="Inactive"
            rightLabel="Active"
            size="medium"
            name="status"
            checked={userObj.status === ACTIVE_STATUS}
            handleChange={handleChange}
            onClick={(e: any) => e.stopPropagation()}
            disabled={false}
          />
          {errors.status && <span className={classes.errorTextBoxStyle}>{errors.status}</span>}
        </div>

        <div className={classes.fieldDivStyle}>
          <span className={classes.fieldTitleSpanStyle}>Role</span>
          <SelectBox
            name="role"
            label=""
            menuItems={rolesList}
            value={userObj.role}
            handleChange={handleChange}
            className={classes.textBoxStyle}
            fieldLabel={undefined}
            error={errors.role !== ""}
            helperText={errors.role}
            menuPaperProps={undefined}
            id={undefined}
            anchorOrigin={undefined}
            hideIconComponent={undefined}
            labelClass={undefined}
          />
          {errors.role && <span className={classes.errorTextBoxStyle}>{errors.role}</span>}
        </div>

        <div className={classes.fieldDivStyle}>
          <span className={classes.fieldTitleSpanStyle}>Service Type</span>
          <MultiSelectBox
            name="serviceTypeId"
            label=""
            menuitems={serviceTypeList}
            value={userObj.serviceTypeId}
            onChange={handleServiceTypeChange}
            className={classes.textBoxStyle}
            fieldLabel={undefined}
            menuPaperProps={undefined}
            anchorOrigin={undefined}
            hideIconComponent={undefined}
          />
          {errors.serviceTypeId && <span className={classes.errorTextBoxStyle}>{errors.serviceTypeId}</span>}
        </div>

        <div className={classes.fieldDivStyle}>
          <span className={classes.fieldTitleSpanStyle}>Country</span>
          {userObj && userObj.role && (userObj.role.includes(MAF_USER) || userObj.role.includes(SUPER_ADMIN) || userObj.role.includes(READONLY_USER) || userObj.role.includes(APPROVER)) ? (
            <>
              <MultiSelectBox
                name="countryId"
                label=""
                menuitems={countriesList}
                value={userObj.countryId}
                onChange={handleCountryChange}
                className={classes.textBoxStyle}
                fieldLabel={undefined}
                menuPaperProps={undefined}
                anchorOrigin={undefined}
                hideIconComponent={undefined}
              />
              {errors.countryId && <span className={classes.errorTextBoxStyle}>{errors.countryId}</span>}
            </>
          ) : (
            <>
              <SelectBox
                name="country"
                label=""
                menuItems={countriesList}
                value={userObj.country}
                handleChange={handleChange}
                className={classes.textBoxStyle}
                fieldLabel={undefined}
                error={errors.country !== ""}
                helperText={errors.country}
                menuPaperProps={undefined}
                id={undefined}
                anchorOrigin={undefined}
                hideIconComponent={undefined}
                labelClass={undefined}
              />
              {errors.country && <span className={classes.errorTextBoxStyle}>{errors.country}</span>}
            </>
          )}
        </div>

        <div className={classes.fieldDivStyle}>
          <span className={classes.fieldTitleSpanStyle}>Additional Services</span>
          <MultiSelectBox
            name="featureId"
            label=""
            menuitems={additionalServicesList}
            value={userObj.featureId}
            onChange={handleFeatureChange}
            className={classes.textBoxStyle}
            fieldLabel={undefined}
            menuPaperProps={undefined}
            anchorOrigin={undefined}
            hideIconComponent={undefined}
          />
          {errors.featureId && <span className={classes.errorTextBoxStyle}>{errors.featureId}</span>}
        </div>
      </Collapse>
    </div>
  );
};

export default UserProfileDetails;
